.partnership {
  padding-bottom: 30px;

  &__img {
    margin-bottom: 20px;
    max-width: 330px;
    margin-left: auto;
    margin-right: auto;
  }

  &__header {
    text-align: center;
  }

  &__list {
    color: $black-title;

    & li {
      margin: 17px 0;
    }
  }
}

@media (min-width: $tablet-width) {
  .partnership {
    display: grid;
    grid-template-columns: min-content 1fr;

    align-items: start;
    column-gap: 17px;
    padding-bottom: 48px;

    &__list {
      padding-right: 40px;
    }

    &__img {
      width: 332px;
      max-width: initial;
      position: relative;
      right: 10px;
    }

    &__header {
      text-align: left;
      line-height: 49px;
    }
  }
}


@media (min-width: $desktop-width) {
  .partnership {
    align-items: center;
    column-gap: 0;
    padding-bottom: 90px;

    &__list {
      display: grid;
      grid-template-columns: 302px 262px;
      padding-right: 0;
      column-gap: 33px;
      column-gap: 33px;
      row-gap: 20px;
      margin: 42px 0;

      li {
        margin: 0;
      }
    }

    &__img {
      width: 580px;
      right: 80px;
    }
  }
}
