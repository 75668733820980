.video {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.04),
    0 0 2px rgba(0, 0, 0, 0.06),
    0 0 1px rgba(0, 0, 0, 0.04);
  border-radius: 30px;
  position: relative;
  cursor: pointer;

  &__button-play {
    position: absolute;
    background-color: transparent;
    border: none;
    width: 70px;
    height: 70px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;

    & svg {
      width: 100%;
      height: 100%;
    }
  }
}


@media (min-width: $tablet-width) {
  .video {
    &__button-play {
      width: 100px;
      height: 100px;
    }
  }
}
