html {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

a {
  color: initial;
  text-decoration: none;
}

strong {
  font-weight: 600;
}

.nowrap {
  white-space: nowrap;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

p {
  margin-bottom: 1.3em;
  margin-top: 1.2em;
}

picture {
  display: block;
  overflow: hidden;
}

figure {
  margin: 36px 0;

  & figcaption {
    font-size: 12px;
    line-height: 14px;
    margin-top: 8px;
    text-align: center;
    font-weight: 300;
    color: $grey-bg-figcaption;
  }
}


img {
  display: block;
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.hidden-mb {
  @media (max-width: $mobile-width-max) {
    display: none;
  }
}

.hidden-tb {
  @media (min-width: $tablet-width) and (max-width: $desktop-width-max) {
    display: none;
  }
}

.hidden-ds {
  @media (min-width: desktop-width) {
    display: none;
  }
}

.wrapper {
  padding-left: 20px;
  padding-right: 20px;
  margin: 0 auto;
  max-width: $desktop-width;
}

.wrapper-big {
  margin: 0 auto;
  max-width: $desktop-width-large;
}

.accent {
  color: $accent-color;
}

.order1 {
  order: 1;
}

.order-1 {
  order: -1;
}

@media (min-width: $tablet-width) {
  .wrapper {
    padding-left: 30px;
    padding-right: 30px;
  }

  figure {
    margin: 58px 0;

    & figcaption {
      font-size: 14px;
      line-height: 17px;
      margin-top: 18px;
      text-align: left;
    }
  }
}

@media (min-width: $desktop-width) {
  .wrapper {
    padding-left: 30px;
    padding-right: 10px;
  }
}
