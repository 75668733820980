.scheme {
  padding-top: 31px;
  padding-bottom: 36px;
  background: $grey-bg;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.04),
    0 2px 6px rgba(0, 0, 0, 0.04),
    0 0 1px rgba(0, 0, 0, 0.04);

  &__header {
    text-align: center;
  }

  &__icon {
    width: 30px;
    height: 30px;
  }

  &__list {
    &-item {
      display: grid;
      grid-template-columns: min-content 1fr;
      column-gap: 12px;
      margin-top: 26px;
    }
  }
}

@media (min-width: $tablet-width) {
  .scheme {
    box-shadow: none;
    background-color: initial;
    padding-top: 43px;

    &__icon {
      width: 60px;
      height: 60px;
    }

    &__list {
      font-size: 24px;
      line-height: 29px;
      margin: 90px 117px 0;

      &-item {
        column-gap: 33px;
        margin-top: 61px;
      }
    }
  }
}


@media (min-width: $desktop-width) {
  .scheme {
    padding-top: 182px;

    &__list {
      margin: 83px 350px 0;
    }
  }
}
