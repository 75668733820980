.form {
  &__input {
    background: #ffffff;
    border: 2px solid #fbb03b;
    font-weight: 300;
    padding: 9px 16px;
    margin-bottom: 31px;
    width: 100%;

    &--error {
      border-color: $angry-color;

      & + .form__validity {
        display: inline-block;
      }
    }
  }


  &__item {
    position: relative;
    display: block;
  }

  &__validity {
    display: none;
    color: $angry-color;
    font-size: 14px;
    line-height: 17px;
    font-weight: 300;
    position: absolute;
    left: 0;
    bottom: 15px;
  }


  &__btn {
    margin-bottom: 20px;

    width: 100%;
  }

  &__secondary-text {
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    display: inline-block;
    margin: 0 44px;
  }
}

@media (min-width: $tablet-width) {
  .form {
    & input {
      padding: 14px 24px;
      margin-bottom: 31px;
      font-size: 24px;
      line-height: 29px;
    }

    &__secondary-text {
      margin: 0;
      font-size: 14px;
      line-height: 17px;
    }

    &__btn {
      margin-bottom: 13px;
    }
  }
}

